<template>
  <div>
    <!-- Title -->
    <b-card-title class="text-primary mb-3">Verify OTP</b-card-title>

    <!-- Alert -->
    <b-alert variant="info" dismissible show fade>
      An OTP has been sent to your mobile number. Please enter the OTP.
    </b-alert>

    <verify-otp :otpData="otpData" @success="otpSuccess" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import VerifyOtp from "../../../../../components/auth/VerifyOtp.vue";
export default {
  components: { VerifyOtp },
  data() {
    return {
      otpData: this.$route.params.otpData,
    };
  },
  methods: {
    ...mapActions({
      saveToken: "login/saveToken",
    }),
    otpSuccess(data) {
      //save token
      const token = data.token;
      if (token) {
        this.saveToken(token).then(() => {
          // redirect to place order screen
          this.$router.replace({ name: "CartPlaceOrder" });
        });
      }
    },
  },
};
</script>

<style></style>
